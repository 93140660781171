'use client';

import cn from 'classnames';
import React from 'react';

import Style from './ButtonCustom.module.scss';

type Props = {
  btnText?: string;
  className?: string;
  extraClass?: string;
  moduleClass?: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  role?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonCustom = ({
  btnText,
  className = '',
  extraClass = '',
  moduleClass = '',
  children,
  onClick,
  role = 'button',
  type = 'button',
  disabled,
  ...otherProps
}: Props) => {
  return (
    <button
      role={role}
      className={cn(
        Style.btn,
        {
          [Style[className]]: className,
          [Style[extraClass]]: extraClass,
        },
        moduleClass,
        extraClass,
      )}
      disabled={disabled}
      type={type}
      onClick={onClick}
      {...otherProps}
    >
      <span>{children ? children : btnText}</span>
    </button>
  );
};

export default ButtonCustom;
