import React from 'react';
import { NumericFormat } from 'react-number-format';

export const PriceFormat: React.FC<{
  value: number | string;
  prefix?: string;
  suffix?: string;
  thousandSeparator?: string;
}> = (props) => {
  return (
    <NumericFormat
      {...props}
      displayType={'text'}
      thousandSeparator={' '}
      decimalScale={2}
      prefix={props?.prefix + ' '}
    />
  );
};
