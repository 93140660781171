'use client';
import { Box, Heading, Text } from '@chakra-ui/layout';
import Image from 'next/image';
import Link from 'next/link';

import {
  kiaFacebookUrl,
  kiaInstagramUrl,
  kiaLinkedinUrl,
  kiaTikTokUrl,
  kiaTwitterUrl,
  kiaYouTubeUrl,
} from '@/constants/links/links.constants';
import { useOpenFloatingCTA } from '@/hooks/floating-cta.hook';

import ButtonCustom from '../button/ButtonCustom';

import Styles from './CTA.module.scss';

export default function Component() {
  const openLeadForm = useOpenFloatingCTA();

  const handleClick = () => {
    openLeadForm('signup', 'Signup');
  };

  return (
    <div className={Styles.footerTop}>
      <div className={Styles.footerCta}>
        <div className={Styles.headingElement}>
          <Heading as="h3" className={Styles.titleMd} color={'white'}>
            Don’t miss a beat
          </Heading>
          <Text className={Styles.titleText}>
            Sign up to get the latest updates
          </Text>
        </div>
        <ButtonCustom
          className="btnOutlineWhite"
          onClick={handleClick}
          moduleClass={Styles.ctaBtn}
        >
          Stay updated
        </ButtonCustom>
      </div>
      <div className={Styles.footerSocialWrapper}>
        <div className={Styles.footerSocialLinkWrap}>
          <Box className={Styles.footerSocialLink}>
            <Link
              href={kiaFacebookUrl}
              target="_blank"
              rel="noreferrer"
              key={'kiaFacebookUrl'}
              data-cy="facebook-icon"
              className={Styles.socialLink}
            >
              <Image
                alt="Facebook"
                src="/icons/facebook.svg"
                width={24}
                height={24}
                className={Styles.socialLinkLogo}
              />
            </Link>
          </Box>
          <Box className={Styles.footerSocialLink}>
            <Link
              href={kiaTwitterUrl}
              target="_blank"
              rel="noreferrer"
              key={'kiaTwitterUrl'}
              data-cy="twitter-icon"
              className={Styles.socialLink}
            >
              <Image
                alt="Twitter"
                src="/icons/new-twitter.svg"
                width={24}
                height={24}
                className={Styles.socialLinkLogo}
              />
            </Link>
          </Box>
          <Box className={Styles.footerSocialLink}>
            <Link
              href={kiaLinkedinUrl}
              target="_blank"
              rel="noreferrer"
              key={'kiaLinkedInUrl'}
              data-cy="linkedIn-icon"
              className={Styles.socialLink}
            >
              <Image
                alt="Linkedin"
                src="/icons/linkedin.svg"
                width={24}
                height={24}
                className={Styles.socialLinkLogo}
              />
            </Link>
          </Box>
          <Box className={Styles.footerSocialLink}>
            <Link
              href={kiaInstagramUrl}
              target="_blank"
              rel="noreferrer"
              key={'kiaInstagramUrl'}
              className={Styles.socialLink}
            >
              <Image
                alt="Instagram"
                src="/icons/instagram.svg"
                width={24}
                height={24}
                className={Styles.socialLinkLogo}
              />
            </Link>
          </Box>
          <Box className={Styles.footerSocialLink}>
            <Link
              href={kiaYouTubeUrl}
              target="_blank"
              rel="noreferrer"
              key={'kiaYouTubeUrl'}
              data-cy="youtube-icon"
              className={Styles.socialLink}
            >
              <Image
                alt="Youtube"
                src="/icons/youtube.svg"
                width={24}
                height={24}
                className={Styles.socialLinkLogo}
              />
            </Link>
          </Box>
          <Box className={Styles.footerSocialLink}>
            <Link
              href={kiaTikTokUrl}
              target="_blank"
              rel="noreferrer"
              key={'kiaTikTokUrl'}
              data-cy="tikTok-icon"
              className={Styles.socialLink}
            >
              <Image
                alt="Tiktok"
                src="/icons/tikTok.svg"
                width={24}
                height={24}
                className={Styles.socialLinkLogo}
              />
            </Link>
          </Box>
        </div>
      </div>
    </div>
  );
}
