'use client';

import cn from 'classnames';
import { getImageProps } from 'next/image';
import React from 'react';

import { ImageTransformationKeys } from '@/types/files/files.types';
import { directusImageLoader } from '@/utils/loaders';

import Styles from './CustomImage.module.scss';

type Props = {
  imageKeys: ImageTransformationKeys;
  imageSrc: string | undefined;
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
  loading?: 'lazy' | 'eager';
  additionalParams: Record<string, string>;
};
const CustomImage = ({
  imageKeys,
  imageSrc,
  alt = '',
  width = 1440,
  height = 680,
  className = '',
  loading = 'lazy',
  additionalParams = {},
}: Props) => {
  const common = {
    alt: alt,
    width: width,
    height: height,
    loader: directusImageLoader,
    priority: loading === 'eager' ? true : false,
  };

  const { srcSet: mobileSrcSet } = getImageProps({
    ...common,
    src: imageSrc
      ? `${imageSrc}?${new URLSearchParams({ ...additionalParams, key: imageKeys.mobileKey }).toString()}`
      : '',
  }).props;

  const { srcSet: desktopSrcSet, ...imageProps } = getImageProps({
    ...common,
    src: imageSrc
      ? `${imageSrc}?${new URLSearchParams({ ...additionalParams, key: imageKeys.desktopKey }).toString()}`
      : '',
  }).props;

  return (
    <picture
      className={cn(
        Styles.customImage,
        {
          [Styles[className] as string]: className,
        },
        className,
      )}
    >
      <source media="(max-width: 575px)" srcSet={mobileSrcSet} />
      <source media="(min-width: 576px)" srcSet={desktopSrcSet} />
      <img alt={alt} {...imageProps} />
    </picture>
  );
};

export default CustomImage;
