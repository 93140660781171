'use client';

import { useSearchParams } from 'next/navigation';

export default function Component() {
  const query = useSearchParams();

  if (query && query.get('preview')) {
    // accessing the query params needs to happen here in client component
    // when wrapped in a Suspense, otherwise pages can't be rendered statically
    // NextJS seems to place this in the head anyway, so don't worry
    return <meta name="robots" content="noindex,nofollow" />;
  }
  return null;
}
