'use client';

import { useEffect } from 'react';

/*
 * This component makes sure we reload the route if we go back and we set something on the state.
 */
export default function Pop() {
  useEffect(() => {
    function popState(e: PopStateEvent) {
      if (e.state !== null) {
        location.reload();
      }
    }
    window.addEventListener('popstate', popState);
    return () => {
      window.removeEventListener('popstate', popState);
    };
  }, []);

  return null;
}
