import { COOKIE_NAME_DEALERSHIP_ID } from '@/constants/dealership/dealership.constants';

import { getDealershipName } from './DealershipService';

export const pushFormSubmittedEvent = (
  formid: string,
  formname: string,
  formElement?: HTMLElement | null,
): void => {
  const formData = {} as {
    formClasses?: string;
    formTarget?: string;
  };

  if (formElement) {
    formData.formClasses = formElement.querySelector('form')?.classList.value;
    formData.formTarget = formElement.querySelector('form')?.target;
  }

  (window as any)?.dataLayer?.push({
    event: 'form_submitted',
    'Form ID': formid,
    'Form Text': formname,
    'Form URL': window.location.href,
    'Form Classes': formData.formClasses,
    'Form Target': formData.formTarget,
  });
};

export const setDealershipCookieOnUser = async () => {
  function getCookie(name: string) {
    let matches = document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') +
          '=([^;]*)',
      ),
    );

    return matches ? decodeURIComponent(matches[1] || '') : '';
  }

  // Get the Microsite Dealership cookie value
  const micrositeDealershipId = getCookie(COOKIE_NAME_DEALERSHIP_ID);

  const micrositeDealershipName = micrositeDealershipId
    ? await getDealershipName(micrositeDealershipId)
    : '';

  function gtag(..._args: any[]) {
    (window as { dataLayer?: any[] })?.dataLayer?.push(arguments);
  }

  gtag('set', 'user_properties', {
    microsite_dealership: micrositeDealershipName,
  });
};
