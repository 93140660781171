'use client';

import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/navigation';
import { setCookie } from 'nookies';
import { useEffect } from 'react';

import { COOKIE_NAME_DEALERSHIP_ID } from '@/constants/dealership/dealership.constants';
import { setDealershipCookieOnUser } from '@/services/gtm.service';

export default function Component() {
  const query = useSearchParams();

  const router = useRouter();

  useEffect(() => {
    const queryObj = Object.fromEntries(query || []);

    if ('utm_dealership' in queryObj) {
      setCookie(null, COOKIE_NAME_DEALERSHIP_ID, queryObj['utm_dealership'], {
        maxAge: process.env.NEXT_PUBLIC_DEALERSHIP_COOKIE_AGE || 1800,
        path: '/',
      });

      setDealershipCookieOnUser();

      router.refresh();
    }

    const oldUtmJson = sessionStorage.getItem('utm_data') || '{}';
    const oldUtm = JSON.parse(oldUtmJson);

    sessionStorage.setItem(
      'utm_data',
      JSON.stringify({ ...oldUtm, ...queryObj }),
    );
  }, [query]);

  return null;
}
