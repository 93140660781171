export const serviceAndMaintenancePlans = [
  {
    name: 'Service Plan',
    link: 'https://financialservices.kia.co.za/service-maintenance-plans/service-plan/',
  },
  {
    name: 'Maintenance Plan',
    link: 'https://financialservices.kia.co.za/service-maintenance-plans/maintenance-plan/',
  },
  {
    name: 'Maintenance Schedules',
    link: 'https://www.kia.co.za/maintenance-schedule/',
  },
];

export const warrantyOptions = [
  {
    name: 'Kia Warranty',
    link: 'https://financialservices.kia.co.za/warranties/warranty-kia/',
  },
  {
    name: 'Warranty Booster',
    link: 'https://financialservices.kia.co.za/warranties/warranty-booster/',
  },
  {
    name: 'Warranty Parameters',
    link: 'https://www.kia.co.za/warranty-parameters/',
  },
  {
    name: 'Bumper2Bumper Warranty',
    link: 'https://financialservices.kia.co.za/warranties/warranty-bumper-to-bumper/',
  },
];

export const valueAddedProducts = [
  {
    name: 'RenewTech',
    link: 'https://financialservices.kia.co.za/vaps/renewtech/',
  },
  {
    name: 'Adcover',
    link: 'https://financialservices.kia.co.za/vaps/adcover/',
  },
  {
    name: 'Deposit Cover',
    link: 'https://financialservices.kia.co.za/vaps/deposit-cover/',
  },
  {
    name: 'Tyre & Rim',
    link: 'https://financialservices.kia.co.za/vaps/tyre-and-rim/',
  },
  {
    name: 'Vehicle Tracking',
    link: 'https://financialservices.kia.co.za/vaps/vehicle-tracking/',
  },
];

export const finance = [
  {
    name: 'Instalment Agreement',
    link: 'https://financialservices.kia.co.za/finance/finance-options/',
  },
  {
    name: 'Balloon Payment Option',
    link: 'https://financialservices.kia.co.za/finance/finance-residual-offer/',
  },
];

export const financialServiceLeftMenu = [
  {
    name: 'Home',
    path: 'https://financialservices.kia.co.za/',
  },
  {
    name: 'About',
    path: 'https://financialservices.kia.co.za/about/',
  },
  {
    name: 'Contact',
    path: 'https://financialservices.kia.co.za/contact-us/',
  },
  {
    name: 'My Finance Journey',
    path: 'https://financialservices.kia.co.za/finance-journey/',
  },
];
