import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/banner-floating-CTA/BannerFloatingCTA.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/_components/footer/Copyright.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/footer/CTA.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/_components/footer/Footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/footer/MainOffers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/footer/OffersCategory.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/header/Nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/StateWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/StyleWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/utils/RobotsMeta.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/utils/UTMExtractor.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/pop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PreloadResources"] */ "/app/app/preload-resources.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/scrollToTop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["Box"] */ "/app/node_modules/@chakra-ui/layout/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/styles/global.scss");
