export const minWideDesktopScreen = 1300;
export const minDesktop = 1025;
export const maxTablet = 1024;
export const minTablet = 700;
export const maxSmallMobile = 450;
export const desktop = 992;
export const PATH_COUNT = 5;
export const DEFAULT_TITLE =
  'New, Demo, Used Cars For Sale | South Africa | Kia Retail Group';
export const KIA_RETAIL_GROUP = '| Kia Retail Group';
export const KIA_GROUP = '| Kia Group';
export const KIA_RETAIL = ' | Kia Retail';
export const KIA_SPECIAL = 'Kia Specials';
export const KIA_DEALERSHIP = 'Kia Dealership';
export const FOR_SALE = 'For Sale';
export const SOUTH_AFRICA = 'South Africa';
export const SEO_SEARCH_DESCRIPTION =
  'Search {0} Cars For Sale: Visit Kia Retail Group and explore our website and browse through our stock for the full range of {0} cars on offer.';
