import { DEALERSHIP_REQUEST_FIELDS } from '@/constants/dealership/dealership.constants';
import { PATH_COUNT } from '@/constants/vars.constants';
import { directusService, limiter } from '@/services/api.service';
import { mapDealershipResponse } from '@/services/dealership/dealership.service';
import { DealershipReq } from '@/types/request/request.types';

export const getDealership = limiter.wrap(async (dealershipId: string) => {
  const { data } = await directusService.get<DealershipReq | DealershipReq[]>(
    `items/dealerships/${dealershipId}`,
    {
      params: {
        fields: DEALERSHIP_REQUEST_FIELDS.join(','),
      },
    },
  );

  return Array.isArray(data)
    ? mapDealershipResponse(data[0])
    : mapDealershipResponse(data);
});

export const getDealershipName = async (dealershipId: string) => {
  const { data } = await directusService.get<DealershipReq>(
    `items/dealerships/${dealershipId}`,
    {
      params: {
        fields: 'name',
      },
    },
  );

  return data.name;
};

export const getDealershipsIdsList = limiter.wrap(async () => {
  const { data: dealerships }: any = await directusService.get(
    '/items/dealerships',
    {
      params: {
        fields: ['id'],
      },
    },
  );

  const { data: bodyTypes }: any = await directusService.get(
    '/items/spec_body_types',
    {
      params: {
        fields: ['id'],
      },
    },
  );

  const paths = [];

  for (const dealership of dealerships) {
    for (const bodyTypeIndex in bodyTypes) {
      if (paths.length > PATH_COUNT) {
        break;
      }
      const bodyType = bodyTypes[bodyTypeIndex];

      paths.push({
        params: {
          id: dealership.id.toString(),
          bodyType: bodyType.id.toString(),
        },
      });
    }
  }

  return { paths };
});
