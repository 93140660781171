import { Box } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import React, { Suspense } from 'react';

import type { PopiaNoticeField } from '@/types/config/config.types';
import { NewCar } from '@/types/new-cars/new-cars.types';

export enum LeadFormEnum {
  'parts' = 'parts',
  'service' = 'service',
  'newsletter' = 'newsletter',
  'new-cars' = 'new-cars',
  'general' = 'general',
  'signup' = 'signup',
  'fleet' = 'fleet',
  'test-drive' = 'test-drive',
  'trade-in' = 'trade-in',
  'used-cars' = 'used-cars',
  'finance' = 'finance',
}

export type LeadFormType = `${LeadFormEnum}`;

const BookServiceForm = dynamic(
  () =>
    import(
      '../enquire-form-after-sales/book-service-form/book-service-form'
    ).then((module) => module.BookServiceForm),
  {
    ssr: false,
  },
);

const PartsEnquireForm = dynamic(
  () =>
    import(
      '../enquire-form-after-sales/parts-enquire-form/parts-enquire-form'
    ).then((module) => module.PartsEnquireForm),
  {
    ssr: false,
  },
);

const NewsletterForm = dynamic(
  () =>
    import('../newsletter-signup/newsletter/newsletter').then(
      (module) => module.Newsletter,
    ),
  {
    ssr: false,
  },
);

const CarsForm = dynamic(
  () =>
    import('../enquire-now-form-new-cars/enquire-now-form-new-cars').then(
      (module) => module.EnquireNowFormNewCars,
    ),
  {
    loading: () => <p>Loading...</p>,
    ssr: true,
  },
);

const FinanceForm = dynamic(
  () =>
    import('../apply-finance/apply-finance-form').then(
      (module) => module.FinanceForm,
    ),
  {
    ssr: false,
  },
);

interface Props {
  type?: LeadFormType | null;
  onClose: () => void;
  newCars: NewCar[];
  documentPaiaPopia: PopiaNoticeField;
  dealerName?: string;
}

export function EnquiryForms({
  type,
  onClose,
  newCars,
  dealerName,
  documentPaiaPopia,
}: Props) {
  const renderForm = (type: LeadFormType) => {
    switch (type) {
      case 'parts':
        return (
          <PartsEnquireForm
            onClose={onClose}
            documentPaiaPopia={documentPaiaPopia}
            dealerName={dealerName}
          />
        );
      case 'service':
        return (
          <BookServiceForm
            onClose={onClose}
            documentPaiaPopia={documentPaiaPopia}
            dealerName={dealerName}
          />
        );
      case 'signup':
      case 'newsletter':
        return (
          <NewsletterForm
            onClose={onClose}
            documentPaiaPopia={documentPaiaPopia}
          />
        );
      case 'used-cars':
      case 'new-cars':
      case 'general':
      case 'test-drive':
      case 'fleet':
      case 'trade-in':
        return (
          <CarsForm
            onClose={onClose}
            newCars={newCars}
            documentPaiaPopia={documentPaiaPopia}
            dealerName={dealerName}
          />
        );
      case 'finance':
        return <FinanceForm onClose={onClose} newCars={newCars} />;
      default: {
        const exhaustiveCheck: never = type;

        throw new Error(`Unhandled lead type ${exhaustiveCheck}`);
      }
    }
  };

  return (
    <Box>{type && <Suspense fallback={null}>{renderForm(type)}</Suspense>}</Box>
  );
}
