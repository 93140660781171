'use client';

import ReactDOM from 'react-dom';

export function PreloadResources() {
  ReactDOM.preconnect(
    process.env.NEXT_PUBLIC_DIRECTUS_API_URL || 'https://proxy.kiaretail.co.za',
  );

  return null;
}
